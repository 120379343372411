
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { useEffect, useRef } from 'react';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { DefaultSeo } from 'next-seo';
import useTranslation from 'next-translate/useTranslation';
import TagManager from 'react-gtm-module';
import { useRouter } from 'next/router';
import omit from 'lodash/omit';
import { useToaster } from 'rsuite';
import Layout from '../components/Layouts/PageLayout';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';
import SwrWrapper from '../components/SwrWrapper';
import { ProvideAuth } from '../services/useAuth';
import SEO from '../next-seo.config';
import { getGtmAuth, getGtmEnv, isProduction } from '../utils/environment';
import contactEvent from '../utils/analytics/contactEvent';
import { getCountryAndLocaleStrings, getCurrencyCode } from '../utils/locales';
import { INTERNAL_TRAFFIC_COOKIE_NAME, localizedSortValuesDict, QueryParam } from '../utils/constants';
import { Locale } from '../external';
import { generateDroppeClientIDCookie, getCookie, hasCookie, triggerInternalTrafficPrompt } from '../utils/cookies';
import TrackingProvider from '../services/trackingContext/trackingContext';
import { CartProvider } from '../components/Cart/CartProvider';
import '../polyfill';
import '../styles/globals.less';
import '../styles/utils/css-variables.less';
import { StrapiFooterResp } from '../utils/types/strapi';
import changeLanguageEvent from '../utils/analytics/changeLanguageEvent';
import useIsUrlHashIncluded from '../services/useIsUrlHashIncluded';
import { getTaxRate } from '../lib/resources/price';
import pageNotFoundEvent from '../utils/analytics/pageNotFoundEvent';
import useCyModel from '../services/useCyModel';
const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || '';
const growthbook = new GrowthBook({
    apiHost: process.env.NEXT_PUBLIC_GROWTHBOOK_HOST || '',
    clientKey: process.env.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY || '',
    enableDevMode: !isProduction,
    trackingCallback: (experiment, result) => {
        // Push experiment data to datalayer
        TagManager.dataLayer({
            dataLayer: {
                event: 'experiment_viewed',
                experiment_id: experiment.key,
                variation_id: result.variationId,
            },
        });
    },
});
const MyApp = ({ Component, pageProps }: AppProps) => {
    const { t } = useTranslation('meta');
    const toaster = useToaster();
    const r = useRouter();
    const { asPath, locale: countryAndLocale, isReady, query, replace, pathname, ...router } = r;
    // Used to prevent change_language event from firing on the first render
    // (should only fire when language/country is changed by user)
    const preventChangeLanguageEventFromTriggering = useRef(true);
    // Workaround to include footer content via SSG
    // TODO: Implement globally to Layout component in Next.js 13+
    type FooterContentRespProps = {
        footerContentResp: StrapiFooterResp;
    };
    const { footerContentResp }: FooterContentRespProps = (pageProps as {
        fallback: FooterContentRespProps;
    })?.fallback || {};
    const { country, locale } = getCountryAndLocaleStrings(countryAndLocale);
    const isModalHashIncluded = useIsUrlHashIncluded(['#quote', '#document', '#sample', '#login', '#register']);
    useEffect(() => {
        if (!hasCookie('droppe_client_id')) {
            generateDroppeClientIDCookie();
        }
        // Load Growthbook features asynchronously when the app renders
        growthbook.loadFeatures({
            autoRefresh: true,
        });
        // Set Growthbook URL on route change
        const updateGrowthBookURL = () => growthbook.setURL(window.location.href);
        router.events.on('routeChangeComplete', updateGrowthBookURL);
        // If internal traffic cookie doesn't exist, create it (unless in production)
        if (!isProduction && !hasCookie(INTERNAL_TRAFFIC_COOKIE_NAME))
            triggerInternalTrafficPrompt();
        const tagManagerConfig = {
            gtmId: GTM_ID,
            preview: getGtmEnv(),
            auth: getGtmAuth(),
            dataLayer: {
                language: locale,
                country,
                country_tax_rate: getTaxRate(country),
                active_currency: getCurrencyCode(country),
            },
        };
        // Initialize GTM and datalayer
        TagManager.initialize(tagManagerConfig);
        // ANALYTICS: Chat started
        if (typeof window !== 'undefined') {
            window.addEventListener('load', () => {
                if (window.chaport) {
                    window.chaport.on('chat.started', () => contactEvent('chat_started', // contact_context parameter, deprecated. Will be removed after a period of time.
                    'chat'));
                }
            });
        }
        // Cleanup function
        return () => {
            router.events.off('routeChangeComplete', updateGrowthBookURL);
        };
    }, []);
    // ANALYTICS: Keep dataLayer in sync with the latest country, language and tax rate
    useEffect(() => {
        // Don't fire the change_language event on the first render
        if (!preventChangeLanguageEventFromTriggering.current) {
            changeLanguageEvent(locale, country, getTaxRate(country));
        }
        preventChangeLanguageEventFromTriggering.current = false;
    }, [country, locale]);
    // Generate client id and set growthbook attributes
    // Custom client ID is used in case user has blocked GA4 / analytics consent
    useEffect(() => {
        if (!hasCookie('droppe_client_id')) {
            generateDroppeClientIDCookie();
        }
        const gaCookie = getCookie('_ga');
        const droppeClientIdCookie = getCookie('droppe_client_id');
        const droppeClientId = droppeClientIdCookie?.[1];
        const gaClientId = gaCookie?.[1].substring(6) || '';
        growthbook.setAttributes({
            isInternalTraffic: hasCookie(INTERNAL_TRAFFIC_COOKIE_NAME),
            clientId: droppeClientId,
            gaClientId,
            language: locale,
            country,
            browser: navigator.userAgent,
            path: asPath,
        });
    }, [locale, country, asPath]);
    // On the client side, once the document is accessible and the router has loaded, change the
    // html tag lang attribute value to the single locale format (i.e. en, fi) instead of the
    // country-locale format.
    useEffect(() => {
        if (document && document.readyState !== 'loading' && isReady && countryAndLocale) {
            document.documentElement.lang = locale;
        }
    }, [countryAndLocale, isReady]);
    // Minimize Chaport when a modal flow is opened
    useEffect(() => {
        if (typeof window !== 'undefined' && window.chaport?.q && isModalHashIncluded) {
            window.chaport.q('close');
        }
    }, [isModalHashIncluded]);
    // This useEffect deals with normalizing localized search and sort (both key and value) query
    // params, specifically when coming from older links with these localized params already preset.
    useEffect(() => {
        if (!isReady || locale === 'en')
            return;
        const localizedSearchQueryValue = query[t('url:search')];
        const localizedSortQueryValue = query[t('url:sort')];
        if (!localizedSearchQueryValue && !localizedSortQueryValue)
            return;
        // The sort query param value was also localized, and so should be normalized first
        const normalizedSortValue = typeof localizedSortQueryValue === 'string' && localizedSortQueryValue
            ? localizedSortValuesDict[localizedSortQueryValue]
            : localizedSortQueryValue;
        const queriesWithOutLocalizedQueries = omit(query, [t('url:search'), t('url:sort')]); // drop the old localized queries first
        const [baseUrl] = asPath.split('?');
        replace({
            pathname: baseUrl,
            query: {
                ...queriesWithOutLocalizedQueries,
                ...(localizedSearchQueryValue && { [QueryParam.search]: localizedSearchQueryValue }),
                ...(localizedSortQueryValue && { [QueryParam.sort]: normalizedSortValue }),
            },
        }, undefined, { shallow: true });
    }, [isReady]);
    // Set useScrollPos session to true if on SPP
    useEffect(() => {
        if (pathname === '/product/[id]') {
            sessionStorage.setItem('isUsingScrollPos', 'true');
        }
    }, [pathname]);
    useCyModel('Router', r);
    useCyModel('Toaster', toaster);
    const isVistorTypeModalVisible = () => {
        if (typeof window === 'undefined')
            return false;
        const overlay = document.getElementById('visitorTypeOverlay');
        if (!overlay)
            return false;
        return window.getComputedStyle(overlay).display !== 'none';
    };
    const showVisitorTypeModal = () => {
        if (typeof window === 'undefined')
            return;
        // Check if modal is already visible
        if (isVistorTypeModalVisible()) {
            return;
        }
        // Check if functions exist and show modal
        if (window?.showModal && window?.updateModalContent) {
            window.updateModalContent();
            window.showModal();
        }
    };
    // Check if customerType cookie exists, otherwise trigger the modal
    useEffect(() => {
        window?.cookieStore?.addEventListener('change', (event: Event) => {
            if (!hasCookie('customerType')) {
                // Make sure the GTM script has loaded and functions are available
                if (window?.showModal && !isVistorTypeModalVisible()) {
                    showVisitorTypeModal();
                }
            }
        });
        return () => window?.cookieStore?.removeEventListener('change');
    }, []);
    // Analytics tracking for 404s
    useEffect(() => {
        if (pathname === '/404') {
            pageNotFoundEvent({
                page_path: `/${countryAndLocale}${asPath}`,
                country,
                language: locale,
            });
        }
    }, [pathname, asPath, country, locale]);
    // The outer and the inner most ErrorBoundary component instance is needed
    // to display the Fallback ui with layout.
    // The middle ErrorBoundary instance is needed to catch the layout error.
    // Skip rendering full layout for oauth redirect page it was doing
    // extra requests that were messing with the session
    if (pathname === '/oauth/[action]/[provider]') {
        return (<ProvideAuth>
        <DefaultSeo {...(SEO(t, locale as Locale))}/>
        <TrackingProvider>
          <ErrorBoundary>
            <Component {...pageProps}/>
          </ErrorBoundary>
        </TrackingProvider>
      </ProvideAuth>);
    }
    return (<ErrorBoundary includeLayout>
      <GrowthBookProvider growthbook={growthbook}>
        <ProvideAuth>
          <SwrWrapper>
            <DefaultSeo {...(SEO(t, locale as Locale))}/>
            <ErrorBoundary>
              <CartProvider>
                <TrackingProvider>
                  <Layout footerContentResp={footerContentResp}>
                    <ErrorBoundary>
                      <Component {...pageProps}/>
                    </ErrorBoundary>
                  </Layout>
                </TrackingProvider>
              </CartProvider>
            </ErrorBoundary>
          </SwrWrapper>
        </ProvideAuth>
      </GrowthBookProvider>
    </ErrorBoundary>);
};
const __Next_Translate__Page__195fa5b0e3c__ = MyApp;
export function reportWebVitals({ id, name, label, value, startTime }: NextWebVitalsMetric) {
    TagManager.dataLayer({
        dataLayer: {
            event: 'nextWebVitals',
            performanceMetric: {
                name,
                value: Math.round(name === 'CLS' ? value * 1000 : value),
                id,
                label,
                startTime,
            },
        },
    });
}

    export default __appWithI18n(__Next_Translate__Page__195fa5b0e3c__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  